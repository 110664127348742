<template>
  <div></div>
</template>

<script>
  /**
   * 空白页跳转
   * 短信链接是这个路由，需要跳转
   * signDetail 页面
   */
  export default {
    name: 'FileDetail',
    created() {
      this.getQuery()
    },
    methods: {
      getQuery() {
        const { query } = this.$route
        if (query) {
          this.$router.replace({
            name: 'SignDetail',
            query,
          })
        }
      },
    },
  }
</script>

<style lang="scss" scoped></style>
